<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">编辑光控联动规则</div>
    <Form :label-width="130">
      <FormItem prop="stationId" label="" style="margin-right: 0" :label-width="0">
        <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" :data="list" height="500" stripe
        :row-config="{isHover: true}" row-id="id">
          <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
          <vxe-column field="start" title="光控起始值" width="100" header-align="center"></vxe-column>
          <vxe-column field="end" title="光控截止值" width="100" header-align="center"></vxe-column>
          <vxe-column title="控制方式">
            <template #default="params">
              <span :style="{color: params.row.handAll ? 'green' : 'blue'}">{{ params.row.handAll ? '群控' : `组控: ${params.row.groupNames}` }}</span>
            </template>
          </vxe-column>
          <vxe-column field="bright1" title="一路亮度" width="80" header-align="center"></vxe-column>
          <vxe-column field="bright2" title="二路亮度" width="80" header-align="center"></vxe-column>
          <vxe-column field="color1" title="一路色温" width="80" header-align="center"></vxe-column>
          <vxe-column field="color2" title="二路色温" width="80" header-align="center"></vxe-column>
          <vxe-column field="expire" title="持续时长(分)" width="110" header-align="center"></vxe-column>
          <vxe-column field="channel" title="上行通道" width="80" header-align="center"></vxe-column>
          <vxe-column width="115" fixed="right">
            <template #header>
              <Button v-if='funCodes(7122)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
              <Button v-if="funCodes(7122)" size="small" type="info" @click="getList">刷新</Button>
            </template>
            <template #default="params">
              <Button v-if='funCodes(7122)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
              <Button v-if='funCodes(7122)' size="small" type="error" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
            </template>
          </vxe-column>
        </vxe-table>
        <ModalLuxRuleEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLuxRuleEdit from './ModalLuxRuleEdit'
export default {
  name: 'ModalLampEdit',
  components: {
    ModalLuxRuleEdit,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    args: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      showEditModal: false,
      editItem: {},
      loading: false,
      isAdd: false,
      dataRefresh: 0,
      list: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted: function () {
  },
  methods: {
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.editItem.data.infoId = this.item.data.id;
      console.log('hand add', this.editItem);
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.editItem.data.infoId = this.item.data.id;
      console.log('hand edit', this.editItem);
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: `确定要删除此规则吗？`,
        onOk: async () => {
          this.$axios.post(`device/lux/DeleteLightRuleConfig`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      this.$axios.post(`device/lux/QueryLightRuleConfig`, {id: this.item.data.id}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          this.dataRefresh++;
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.edit-form-item{
  border: solid 1px red;
  margin-top: 5px;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>