<template>
<div class="config-device-container">
  <div class="device-data-area">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 140px; margin-right: 10px;"></Input>
      光控设备：
      <Select :placeholder="showLang('save.select')" v-model="filter.luxId" style="width: 120px;margin-right: 10px;">
        <Option value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in args.luxes">
          <Option :value="s.id" :key="idx">{{s.name}}</Option>
        </template>
      </Select>
      <Button v-if="funCodes(7120)" size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" height="auto" stripe
        :row-config="{isHover: true, height: rowHeight}" :checkbox-config="{checkField: 'checked',highlight: true}"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column width="100" fixed="left">
          <template #default="params">
            <span :style="{color: params.row.enable ? 'green' : 'red'}">{{ params.row.enable ? '启用' : '停用' }}</span>
          </template>
        </vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="250" fixed="left" header-align="center"></vxe-column>
        <vxe-column field="luxName" title="关联光控设备" header-align="center"></vxe-column>
        <vxe-column width="260" fixed="right">
          <template #header>
            <Button v-if='funCodes(7121)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <Button v-if="funCodes(7123)" size="small" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
          </template>
          <template #default="params">
            <Button v-if='funCodes(7122)' size="small" :type="params.row.enable ? 'warning' : 'success'" style="margin-right: 5px" @click="handleEnable(params)">{{params.row.enable ? '停用' : '启用'}}</Button>
            <Button v-if='funCodes(7122)' size="small" type="info" style="margin-right: 5px" @click="handleRule(params)">设置规则</Button>
            <Button v-if='funCodes(7122)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
            <Button v-if='funCodes(7123)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <ModalLuxUnionEdit v-model="showEditModal" :item="editItem" :args="args" @saved="itemSaved" />
      <ModalLuxRuleList v-model="showRuleModal" :item="editItem" />
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLuxUnionEdit from './ModalLuxUnionEdit'
import ModalLuxRuleList from './ModalLuxRuleList'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLuxUnionEdit,
    ModalLuxRuleList,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      rowHeight: 60,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showRuleModal: false,
      exportLoading: false,
      dataRefresh: 0,
      filter: {
        luxId: '0',
        name: "",
        size: 20,//20 100 500 1000 5000
        index: 1,
      },
      total: 0,
      form: {
        name: '',
        areaId: 0,
      },
      args: {
        luxes: [],
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    groupTreeSelectedNode(){
      this.search();
    },
  },
  mounted: function () {
    this.queryLuxDevices();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex);
    },
    search: function(){
      this.filter.index=1;
      this.getList();
    },
    queryLuxDevices: function () {
      this.$axios.post(`device/lux/QuerySimpleDevice`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'luxes', res.data);
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    handleRule: function(params){
      this.editItem = { isAdd: false, data: params.row };
      this.showRuleModal = true;
    },
    handleEnable: function(params){
      this.$Modal.confirm({
        title: '系统提示',
        content: `确定要${params.row.enable ? '禁用' : '启用'}该规则吗？`,
        onOk: async () => {
          this.$axios.post(`device/lux/EnableLightUnionConfig`, { id: params.row.id, enable: !params.row.enable }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`device/lux/DeleteLightUnionConfig`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`device/lux/DeleteLightUnionConfig`, { list: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      this.$axios.post(`device/lux/QueryLightUnionConfig`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          this.dataRefresh++;
        }
      });
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding: 10px; */
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
    background: #fff;
}
.query-data-area {
  border-bottom: solid 1px #dcdee2;
  align-items: center;
  /* margin: 5px 0; */
  padding: 5px 0;
  flex: none;
  min-height: 45px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding: 5px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>